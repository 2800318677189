"use client";

import Lenis from '@studio-freight/lenis';
import type LenisType from '@studio-freight/lenis/types';
import { useRef, useState } from 'react';
import React, { useEffect } from 'react';
import { LenisContext } from '@/context/lenis-context';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import ToastContainer, { ToastType } from './toast-handler/toast-container';

export default function LenisProvider({
    children,
    options,
    ...props
}:
    {
        children: React.ReactNode;
        options?: any;
    })
{
    // state
    const [lenisInstance, setLenisInstance] = useState<LenisType | null>(null);
    const [toasts, setToasts] = useState<ToastType[]>([]);

    const removeToastTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() =>
    {
        const lenis = new Lenis();

        setLenisInstance(lenis);

        gsap.registerPlugin(ScrollTrigger);

        lenis.on('scroll', ScrollTrigger.update);

        gsap.ticker.add((time: number) =>
        {
            lenis.raf(time * 1000);
        });

        gsap.ticker.lagSmoothing(0);

        /* 
        If window is resized or if the document body 
        grows from an accordion we need to manually refresh the scrolltrigger
        */
        let resizeTimeout: NodeJS.Timeout | null = null;
        let resizeObserver = new ResizeObserver(() =>
        {
            resizeTimeout && clearTimeout(resizeTimeout);

            resizeTimeout = setTimeout(() =>
            {
                ScrollTrigger.refresh();
            }, 10);
        });
        resizeObserver.observe(document.body);

        return () =>
        {
            lenis.destroy();
        };

    }, []);

    // queue deletions
    useEffect(() =>
    {
        // still ensures if handle remove toast was called manually we still remove the latest timeout
        removeToastTimeoutRef.current && clearTimeout(removeToastTimeoutRef.current);
        removeToastTimeoutRef.current = setTimeout(() =>
        {
            setToasts((prev) =>
            {
                return [...prev.slice(1)];
            });
        }, 5000);

    }, [toasts]);


    function HandleAddToast(toast: ToastType)
    {
        setToasts((prev) =>
        {
            return [...prev, toast];
        });
    }

    function HandleRemoveToast(id: string)
    {
        setToasts((prev) =>
        {
            return prev.filter((toast) => toast.id !== id);
        });
    }

    function HandleInitializeToasts(toasts: ToastType[])
    {
        setToasts(toasts);
    }

    return (
        <LenisContext.Provider
            value={ {
                lenisInstance,
                requestToasts: toasts,
                initializeToasts: HandleInitializeToasts,
                addToast: HandleAddToast,
                removeToast: HandleRemoveToast
            } }
            { ...props }>
            <ToastContainer />
            { children }
        </LenisContext.Provider>
    );
}