'use client';
import { ReactNode, forwardRef, useContext } from "react";
import { useEffect, useRef } from "react";
import { motion } from 'framer-motion';
import { LenisContext } from "@/context/lenis-context";

// toast media
import {ReactComponent as ToastSuccess} from '@/public/svgs/toast/icon-parent.-success.svg';
import {ReactComponent as ToastInfo} from '@/public/svgs/toast/icon-parent.-info.svg';
import {ReactComponent as ToastWarning} from '@/public/svgs/toast/icon-parent.-warning.svg';
import {ReactComponent as ToastError} from '@/public/svgs/toast/icon-parent.-error.svg';
import {ReactComponent as ToastExit} from '@/public/svgs/toast/toast-exit.svg';

type ToastProps = {
    children?: ReactNode;
    id: string,
    message: string;
    type: 'success' | 'error' | 'info' | 'warning';
    statusCode: number,
    ref: HTMLDivElement;
};

type Ref = HTMLDivElement;

export default forwardRef<Ref, ToastProps>(function Toast(props, ref) /* Forward Ref to handle popLayout */
{
    // use context
    const lenisContext = useContext(LenisContext);

    //  destructing props
    const {
        id,
        statusCode,
        message,
        type,
    } = props;

    // refs
    const isMounted = useRef(false);
    const fetchSignal = useRef<AbortController>();

    useEffect(() =>
    {
        isMounted.current = true;

        return () =>
        {
            isMounted.current = false;
            fetchSignal && fetchSignal.current?.abort();
        };

    }, []);

    const RenderToast = (type: ToastProps['type']) =>
    {
        switch (type)
        {
            case 'success':
                return <ToastSuccess />;
            case 'info':
                return <ToastInfo />;
            case 'error':
                return <ToastError />;
            case 'warning':
                return <ToastWarning />;
            default:
                console.error('Invalid toast type');
        }
    };

    return (
        <motion.div
            ref={ ref }
            layout
            initial={ { translateX: '50%', scale: .7, opacity: 0 } }
            animate={ { translateX: 0, scale: 1, opacity: 1 } }
            exit={ { translateX: '50%', scale: .7, opacity: 0 } }
            transition={ { type: 'spring', duration: 1 } }
            className={ `toast -${ type }` }>
            <div className="group">
                <div className="logo">
                    { RenderToast(type) }
                </div>

                <p className="message">
                    <span>{ statusCode }:</span> { message }
                </p>
            </div>

            <div
                className="close-btn"
                onClick={ () => lenisContext.removeToast(id) }>
                <ToastExit />
            </div>
        </motion.div>
    );
});