import type LenisType from '@studio-freight/lenis/types';
import { createContext } from "react";
import { ToastType } from '@/components/toast-handler/toast-container';

export const LenisContext = createContext({
    lenisInstance: null as LenisType | null,
    requestToasts: [] as ToastType[],
    initializeToasts: (toasts: ToastType[]) => { },
    addToast: (toast: ToastType) => { },
    removeToast: (id: string) => { }
});